export default {
  area: '栾川乡数智管理平台',
  objectName: '',
  //url
  // https://lcmsapi.anluoyun.cn/
  // http://hotelapi.zhsq.cloud/
  hostUrl: 'https://yyjp.zhsq.cloud',
  apiHttpsUrl: 'https://lcmsapi.anluoyun.cn/',
  apiRequestUrl: 'https://lcmsapi.anluoyun.cn/api',
  apiUploadUrl: 'https://lcmsapi.anluoyun.cn/api/UploadFile/UploadImgOrFile',
  apiUploadFiles: 'https://lcmsapi.anluoyun.cn/api/UploadFile/UploadImgOrFile',
  apiUploadvideo: 'https://lcmsapi.anluoyun.cn/api/UploadFile/UploadQiniuVideo',
}
